import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main className="main-container">
      <div className="title-container">
        <h1 className="page-title">404</h1>
      </div>
      <div className="main-content-container">
        <p>
          That page doesn't exist. <Link to="/">Click here</Link> to return
          home.
        </p>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage
